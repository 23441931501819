<template>
    <v-container>
        <base-material-card icon="fas fa-bell" title="Notificaciones" class="px-5 py-3">
            <v-row>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="12">
                            <v-select v-model="notificacion.user_id" :items="usuarios" item-value="id" item-text="name" label="Usuarios" multiple clearable class="required" :error-messages="errors.user_id">
                                <template v-slot:prepend-item>
                                    <v-list-item ripple @click="toggle">
                                        <v-list-item-action>
                                            <v-icon :color="notificacion.user_id.length > 0 ? 'amber darken-3' : ''">{{ icon }}</v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>Todos</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class="mt-2"></v-divider>
                                </template>
                            </v-select>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field v-model="notificacion.encabezado" label="Encabezado"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea v-model="notificacion.contenido" label="Contenido" :error-messages="errors.contenido" class="required"></v-textarea>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12">
                    <v-btn color="primary" @click="save()" :loading="loading">Enviar</v-btn>
                    <v-btn @click="$router.push({path: '/dashboard'})" :loading="loading">Cancelar</v-btn>
                </v-col>
            </v-row>
        </base-material-card>
    </v-container>
</template>
<script>
    const MODEL = {
        nombre: null,
        codigo: null
    }
    export default {
        data: () => ({
            usuarios: [],
            errors: {},
            loading: false,
            notificacion: {
                user_id: []
            }
        }),
        mounted() {
            this.loadUsuarios()
        },
        computed: {
            allUsers() {
                return this.notificacion.user_id.length === this.usuarios.length
            },
            someUsers() {
                return this.notificacion.user_id.length > 0 && !this.allUsers
            },
            icon() {
                if (this.allUsers) return 'mdi-close-box'
                if (this.someUsers) return 'mdi-minus-box'
                return 'mdi-checkbox-blank-outline'
            },
        },
        methods: {
            loadUsuarios() {
                this.$http.get(`/notificaciones/usuarios`).then(response => {
                    this.usuarios = response.data
                })
            },
            save() {
                this.loading = true
                this.errors = {}
                this.$http.post('/notificaciones', this.notificacion).then(response => {
                    this.$store.commit('SET_SNACKBAR', { text: "Transacción exitosa", color: "success" })
                    this.notificacion = {
                        user_id: []
                    }
                }).catch(error => {
                    switch (error.response.status) {
                        case 422:
                            this.errors = error.response.data.errors
                            break;

                        default:
                            break;
                    }
                }).finally(() => {
                    this.loading = false
                })
            },
            toggle() {
                this.$nextTick(() => {
                    if (this.allUsers) {
                        this.notificacion.user_id = []
                    } else {
                        this.notificacion.user_id = this.usuarios.slice()
                    }
                })
            }
        }
    }
</script>